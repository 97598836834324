import { observable } from 'aurelia-framework';
import { EventAggregator } from 'aurelia-event-aggregator';
import { DialogController } from 'aurelia-dialog';
import { DialogService } from 'aurelia-dialog';
import { LeadScripts } from 'services/lead-scripts';
import { Leads } from 'services/leads';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { c } from 'common/common';
import { ScriptOptionDialog } from './script-option-dialog';
PLATFORM.moduleName('./script-option-dialog');

export class EditScriptDialog {
    static inject = [EventAggregator, DialogController, DialogService, LeadScripts, Leads, NewInstance.of(ValidationController)];
    _ea;
    _dialogService;
    dialogController;
    _leadScripts;
    _leads;
    validationController;

    @observable searchOn;

    id;

    title = '';
    deleteTextKey = 'delete';

    scriptTypes = ['start', 'script', 'terminator'];
    _scriptNumber = 1;
    _handlers = [];

    constructor(ea, dialogController, dialogService, leadScripts, leads, validationController) {
        this._ea = ea;
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this._dialogService = dialogService;
        this._leadScripts = leadScripts;
        this._leads = leads;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
    }

    activate(model) {
        model = model ?? {};
        this.id = model.id;
        this.name = model.name ?? '';
        this.scriptType = model.scriptType;
        this.scriptPerson = model.scriptPerson;
        this.script = model.script ?? [];
        if (this.script.length === 0) {
            this.addScript('start', true);
        } else {
            this.script.forEach(s => {
                s.number = this._scriptNumber++;
            });
            this._setCurrentScript(0);
        }
        this.title = this.id ? 'lead:edit-script' : 'lead:add-script';
        this.validationController.reset();
        this._load();
    }

    attached() {
        this._handlers.push(this._ea.subscribe('dnd:didEnd', async() => {
            window.setTimeout(() => this.setCurrentScript(this.currentScript.id), 0);
        }));
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    async _load() {
        try {
            this.variables = this._leads.printTemplateVariables();
        } catch (err) {
            console.log(err);
        }
    }

    searchOnChanged() {
        try {
            this.searching = true;
            this.searchResults = [];
            this.showReturnToSearchResults = false;
            if (!this.searchOn) {
                this.showSearchResults = false;
                return;
            }
            this.showSearchResults = true;
            this.script.forEach(s => {
                const regex = RegExp(this.searchOn.replace(/[-\/\\^$*+?.()|[\]{}]/g, '\\$&'), 'i');
                const foundSearchValue = s.script.search(regex);
                if (foundSearchValue >= 0) this.searchResults.push(s);
            });
        } catch (err) {
            console.log(err);
        } finally {
            this.searching = false;
        }
    }

    _clearSearch() {
        this.showSearchResults = false;
        this.searchResults = [];
        this.searching = false;
    }

    returnToSearchResults() {
        this.searchOnChanged();
    }

    setCurrentScript(id) {
        if (this.showSearchResults) {
            this._clearSearch();
            this.showReturnToSearchResults = true;
        }
        const idx = this.script.findIndex(x => x.id === id);
        this._setCurrentScript(idx);
    }

    _setCurrentScript(index) {
        this.currentScript = this.script[index];
        this.resetScript = this.currentScript.script;
        this.resetProTip = this.currentScript.proTip;

        this.previousScripts = [];
        this.script.forEach(s => {
            if (s === this.currentScript.index) return;
            let optionIndex = 0;
            s.options.forEach(o => {
                if (o.toScriptId === this.currentScript.id) {
                    this.previousScripts.push({ id: s.id, name: s.name, number: s.number, optionIndex: optionIndex, script: s.script, option: o });
                }
                optionIndex++;
            });
        });

        this.nextScripts = [];
        this.currentScript.options.forEach(o => {
            const ns = this.script.find(x => x.id === o.toScriptId);
            if (!ns) return;
            this.nextScripts.push({ id: ns.id, name: ns.name, number: ns.number, script: ns.script, option: o });
        });
        this.save(false);
    }

    addScript(type, editing = false) {
        const id = c.Helpers.uniqueId();
        const newScriptType = type ?? this.script.length === 0 ? 'start' : 'script';
        this.script.push({
            id,
            name: newScriptType === 'start' ? 'Intro' : '',
            number: this._scriptNumber++,
            type: newScriptType,
            script: '',
            options: [],
            editing,
        });
        this.setCurrentScript(id);
    }
    
    deleteScript() {
        try {
            const idx = this.script.findIndex(x => x.id === this.currentScript.id);
            // remove all options going to this script
            this.script.forEach(s => {
                const newOptions = [];
                s.options.forEach(o => {
                    if (o.toScriptId === this.currentScript.id) return;
                    newOptions.push(o);
                });
                s.options = newOptions;
            });
            this.script.splice(idx, 1);
            this._setCurrentScript(0);
        } catch (err) {
            console.log(err);
        }
    }

    scriptNameBlur() {
        this.setCurrentScript(this.currentScript.id);
    }

    scriptChanged(detail) {
        if (!this.currentScript.editing) return;
        this.currentScript.script = detail.html;
    }

    proTipChanged(detail) {
        if (!this.currentScript.editing) return;
        this.currentScript.proTip = detail.html;
    }

    startEditingScript() {
        this.currentScript.editing = true;
    }

    stopEditingScript() {
        this.currentScript.editing = false;
        this.save(false);
    }

    toggleShowCalendar() {
        this.save(false);
    }

    async editScriptOption(index = undefined) {
        if (this.currentScript.editing) return;
        try {
            const option = index !== undefined ? this.currentScript.options[index] : {};
            const goToOptions = [];
            this.script.forEach(s => {
                if (s.id === this.currentScript.id) return;
                goToOptions.push({ id: s.id, name: s.name, number: s.number });
            });
            const goToOption = option.toScriptId ? goToOptions.find(x => x.id === option.toScriptId) : undefined;
            const model = { name: option.name, goToOption, goToOptions };
            this._dialogService.open({ viewModel: ScriptOptionDialog, model, ignoreTransitions: true }).whenClosed(async(response) => {
                if (response.wasCancelled) return;
                const optionName = response.output.name;
                const toScriptId = response.output.toScriptId;
                const newScriptName = `${this.currentScript.name} - ${optionName}`;
                const newScriptId = toScriptId ?? c.Helpers.uniqueId();
                if (option.toScriptId) {
                    option.name = optionName;
                    option.toScriptId = newScriptId;
                } else {
                    this.currentScript.options.push({ toScriptId: newScriptId, name: optionName });
                }
                if (!toScriptId) {
                    this.script.push({ id: newScriptId, name: newScriptName, number: this._scriptNumber++, type: 'script', script: '', options: [], editing: false });
                }

                this.setCurrentScript(this.currentScript.id);
        });
        } catch (err) {
            console.log(err);
        }
    }

    removeScriptOption(index, scriptId) {
        if (this.currentScript.editing) return;
        try {
            scriptId = scriptId ?? this.currentScript.id;
            const script = this.script.find(x => x.id === scriptId)
            const removeToScriptId = script.options[index].toScriptId;
            script.options.splice(index, 1);
            // Only remove the script if it is only referenced by one
            let hasAnotherReference = false;
            this.script.forEach(s => {
                if (!s.options.find(x => x.toScriptId === removeToScriptId)) return;
                hasAnotherReference = true;
            });
            if (hasAnotherReference) {
                this.setCurrentScript(this.currentScript.id);
                return; // there is another option referencing the script, cannot delete the other script
            }
            const removeScriptIndex = this.script.indexOf(x => x.id === removeToScriptId);
            this.script.splice(removeScriptIndex, 1);
            const setCurrentScriptId = removeToScriptId !== this.currentScript.id ? this.currentScript.id : script.id;
            this.setCurrentScript(setCurrentScriptId);
        } catch (err) {
            console.log(err);
        }
    }

    async save(close = true) {
        if (this.saving) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            this.saving = true;

            const payload = {
                id: this.id,
                name: this.name,
                scriptType: this.scriptType,
                scriptPerson: this.scriptPerson,
                script: this.script,
            };
            await this._leadScripts.save(payload);
            if (close) this.dialogController.ok();
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }

    resetDelete() {
        this.showDeleteConfirm = false;
        this.deleteTextKey = 'delete';
    }

    async delete() {
        if (!this.showDeleteConfirm) {
            this.showDeleteConfirm = true;
            this.deleteTextKey = 'delete-confirm';
            return;
        }

        this.showDeleteConfirm = false;
        if (this.deleting) return;
        try {
            this.deleting = true;
            await this._leadScripts.delete(this.id);
            this.dialogController.ok();
        } catch(err) {
            this.resetDelete();
            console.log(err);
        } finally {
            this.deleting = false;
        }
    }
}
