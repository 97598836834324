import { DialogController } from 'aurelia-dialog';
import { NewInstance } from 'aurelia-dependency-injection';
import { ValidationRules, ValidationController } from 'aurelia-validation';
import { BootstrapFormValidationRenderer } from 'validation/bootstrap-form-validation-renderer';
import { LeadScripts, SCRIPT_TYPE, SCRIPT_PERSON } from 'services/lead-scripts';

export class EditScriptNameDialog {
    static inject = [DialogController, NewInstance.of(ValidationController), LeadScripts];
    dialogController;
    validationController;

    name;
    scriptType;
    scriptTypes = SCRIPT_TYPE.all();
    scriptPerson;
    scriptPersons = SCRIPT_PERSON.all();

    constructor(dialogController, validationController, leadScripts) {
        this.dialogController = dialogController;
        this.dialogController.settings.centerHorizontalOnly = true;
        this.validationController = validationController;
        this.validationController.addRenderer(new BootstrapFormValidationRenderer());
        this._leadScripts = leadScripts;

		ValidationRules
            .ensure('name').required()
            .on(this);
    }

    activate(model) {
        model = model ?? {};
        this.id = model.id ?? null;
        this.name = model.name ?? '';
        this.scriptType = model.scriptType ?? SCRIPT_TYPE.Phone;
        this.scriptPerson = model.scriptPerson ?? SCRIPT_PERSON.First;
        this.script = model.script;
        this.titleKey = this.id ? 'lead:edit-script' : 'lead:add-lead-script-title';
    }

    async save() {
        if (this.saving) return;
        try {
            const v = await this.validationController.validate();
            if (!v.valid) return;
            const data = await this._leadScripts.save({ id: this.id, name: this.name, scriptType: this.scriptType, scriptPerson: this.scriptPerson, script: this.script });
            this.dialogController.ok(data);
        } catch (err) {
            console.log(err);
        } finally {
            this.saving = false;
        }
    }
}
