import { LeadScripts, SCRIPT_PERSON, SCRIPT_TYPE } from 'services/lead-scripts';
import { DialogService } from 'aurelia-dialog';
import { EditScriptDialog } from './dialogs/edit-script-dialog';
import { EditScriptNameDialog } from './dialogs/edit-script-name-dialog';
PLATFORM.moduleName('./dialogs/edit-script-dialog');
PLATFORM.moduleName('./dialogs/edit-script-name-dialog');

export class Scripts {
    static inject = [LeadScripts, DialogService];
    _leadScripts;
    _dialogService;

    filters = [
        { key: 'search', value: '', keys: ['name','scriptTypeName','scriptPersonName'] },
        { value: `${SCRIPT_TYPE.Phone},${SCRIPT_TYPE.InPerson}`, list: SCRIPT_TYPE.all(), custom: this.scriptTypeFilter },
        { value: `${SCRIPT_PERSON.First},${SCRIPT_PERSON.Third}`, list: SCRIPT_PERSON.all(), custom: this.scriptPersonFilter },
    ];

    scriptTypes = SCRIPT_TYPE.allWithIcons();
    scriptPersons = SCRIPT_PERSON.allWithIcons();

    _handlers = [];

    constructor(leadScripts, dialogService) {
        this._leadScripts = leadScripts;
        this._dialogService = dialogService;
        this.scriptTypes.forEach(st => st.checked = true);
        this.scriptPersons.forEach(sp => sp.checked = true);
    }

    attached() {
        this._load();
    }

    detached() {
        this._handlers.forEach(h => h.dispose());
        this._handlers = [];
    }

    toggleScriptFilter(index, toggleStatus) {
        const currentList = this.filters[index].value.split(',');
        const idx = currentList.findIndex(x => x === toggleStatus);
        if (idx >= 0) {
            currentList.splice(idx, 1);
        } else {
            currentList.push(toggleStatus);
        }
        this.filters[index].list = currentList;
        this.filters[index].value = currentList.join(',');
    }

    scriptTypeFilter(filterValue, row) {
        if (!filterValue) return false;
        const currentTypes = filterValue.split(',');
        if (currentTypes.includes(row.scriptType)) return true;
        return false;
    }

    scriptPersonFilter(filterValue, row) {
        if (!filterValue) return false;
        const currentPersons = filterValue.split(',');
        if (currentPersons.includes(row.scriptPerson)) return true;
        return false;
    }

    async _load(expandId, showLoader = true) {
        try {
            if (showLoader) this.showLoader = true;
            this.scripts = await this._leadScripts.all();
            this.scripts.forEach(s => {
                s.actions = [
                    { key: 'edit', name: 'lead:edit-script', icon: 'fa-duotone fa-pencil-alt' },
                    { key: 'copy', name: 'lead:copy-script', icon: 'fa-duotone fa-copy' },
                    { key: 'delete', name: 'delete', icon: 'fa-duotone fa-trash-can' },
                ];
            });
            if (expandId) {
                this.scripts.find(x => x.id === expandId).expanded = true;
            }
            await this._loadFilterOptions();
        } catch (err) {
            console.log(err);
        } finally {
            if (showLoader) this.showLoader = false;
        }
    }

    async _loadFilterOptions() {
        try {
            this.filterOptions = await this._leadScripts.filterOptions();
        } catch (err) {
            console.log(err);
        }
    }

    editScript(script) {
	    const model = script || {};
	    this._dialogService.open({ viewModel: EditScriptNameDialog, model: model, ignoreTransitions: true }).whenClosed(async(response) => {
            if (response.wasCancelled) return;
            this._load(response.output.id);
            if (script) return;
            // Only open the editor when adding a new script
            this.openEditor(response.output);
	    });
    }

	openEditor(leadScript) {
	    const model = leadScript || {};
	    this._dialogService.open({ viewModel: EditScriptDialog, model: model, ignoreTransitions: true }).whenClosed(response => {
            this._load(leadScript.id);
	    });
	}

    scriptAction(key, s, index) {
        switch (key) {
            case 'delete': this._delete(s, index); break;
            case 'edit': this.openEditor(s); break;
            case 'copy': this._copy(s); break;
        }
    }

    async _copy(s) {
        try {
            this.showLoader = true;
            const data = await this._leadScripts.copy(s.id);
            await this._load(data.id, false);
        } catch (err) {
            console.log(err);
        } finally {
            this.showLoader = false;
        }
    }

    async _delete(s) {
        try {
            this.showLoader = true;
            await this._leadScripts.delete(s.id);
            await this._load(undefined, false);
        } catch (err) {
            console.log(err);
        } finally {
            this.showLoader = false;
        }
    }

    async setDefault(s) {
        try {
            this.showLoader = true;
            await this._leadScripts.setDefault(s.id);
            this.scripts.forEach(script => {
                // script defaults are per type
                if (s.scriptType != script.scriptType || s.scriptPerson != script.scriptPerson) return;
                script.isDefault = false;
            });
            s.isDefault = true;
        } catch (err) {
            console.log(err);
        } finally {
            this.showLoader = false;
        }
    }

    async leadSourceSelected(s) {
        try {
            if (!s.filter.leadSources.includes(s.addLeadSource)) s.filter.leadSources.push(s.addLeadSource);
            await this._saveFilter(s);
            s.closeLeadSourcePopover = new Date().valueOf();
            s.addLeadSource = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadSource(s, index) {
        try {
            s.filter.leadSources.splice(index, 1);
            await this._saveFilter(s);
        } catch (err) {
            console.log(err);
        }
    }

    async leadSupplierSelected(s) {
        try {
            if (!s.filter.leadSuppliers.includes(s.addLeadSupplier)) s.filter.leadSuppliers.push(s.addLeadSupplier);
            await this._saveFilter(s);
            s.closeLeadSupplierPopover = new Date().valueOf();
            s.addLeadSupplier = undefined;
        } catch (err) {
            console.log(err);
        }
    }

    async removeLeadSupplier(s, index) {
        try {
            s.filter.leadSuppliers.splice(index, 1);
            await this._saveFilter(s);
        } catch (err) {
            console.log(err);
        }
    }

    async _saveFilter(s) {
        await this._leadScripts.saveFilter(s.id, s.filter);
    }
}
